<template>
  <div>
    <a-modal
      class="bill-prepare"
      v-model="visible"
      title="Prepare Bill"
      @ok="handleOk"
      width="800px"
      :destroyOnClose="true"
      :key="compKey"
    >
      <div slot="footer">
        <a-button key="back" @click="handleOk"> Close </a-button>
      </div>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        layout="vertical"
      >
        <a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item
                ref="patient_name"
                label="Patient name"
                prop="patient_name"
              >
                <a-input
                  disabled
                  v-model="form.patient_name"
                  @blur="
                    () => {
                      $refs.patient_name.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="Issue Date" prop="issue_date">
                <VNepaliDatePicker
                  calenderType="Nepali"
                  placeholder="मिति चयन गर्नुहोस्"
                  format="yyyy-mm-dd"
                  classValue="nepalidatepicker"
                  v-on:change="onChange"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12" class="radio">
              <a-form-model-item label="VAT Applicable" style="display: flex">
                <a-radio-group v-model="form.vat">
                  <a-radio value="1" style="margin-left: 20px"> Yes </a-radio>
                  <a-radio value="0"> No </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="Issued By" prop="issue_by">
                <a-input v-model="form.issue_by" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-row>
        <a-row>
          <item-table :vat="form.vat" :forms_values="form" ref="getTableData" />
        </a-row>
        <a-form-model-item :wrapper-col="{ offset: 19 }">
          <a-button
            type="primary"
            @click="getItemTableData"
            style="background-color: #2d67bd; border: #2d67bd; margin-top: 20px"
            :loading="loadingBillPrepare"
          >
            Create
          </a-button>
          <a-button style="margin-left: 10px" @click="resetForm">
            Reset
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import itemTable from "./itemTable.vue";
import { bus } from "../../router/index.js";
import moment from "moment";
import VNepaliDatePicker from "v-nepalidatepicker";

export default {
  components: {
    itemTable,
    VNepaliDatePicker,
  },
  data() {
    return {
      visible: false,
      loadingBillPrepare: false,
      // labelCol: { span: 4 },
      // wrapperCol: { span: 24 },
      individual_data: {},
      compKey: 0,
      form: {
        issue_by: "",
        issue_date: moment().format("YYYY-MM-DD"),
        patient_id: null,
        patient_name: "",
        vat: "",
      },
      rules: {
        // issue_date: [
        //   {
        //     required: true,
        //     message: "Please add issue date",
        //   },
        // ],
        issue_by: [
          {
            required: true,
            message: "Please input the bill issued by name",
            trigger: "blur",
          },
        ],
        patient_name: [
          {
            required: true,
            message: "Please input Patient name",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    bus.$on("loadingBill", (value) => {
      this.loadingBillPrepare = value;
    });
  },
  methods: {
    moment,
    showModal(data) {
      this.visible = true;
      this.individual_data = data;
      this.form.patient_name = this.individual_data.patient_name;
      this.form.patient_id = this.individual_data.id;
    },
    handleOk(e) {
      this.visible = false;
    },
    onChange(newDate) {
      console.log("🚀 ~ onChange ~ newDate:", newDate);
      this.form.issue_date = newDate;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        } else {
          console.log("error submit!!");
          return false;
        }
        this.compKey++;
      });
    },
    getItemTableData() {
      this.loadingBillPrepare = true;
      this.$refs.getTableData.sendTableData(this.loadingBillPrepare);
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.form.issue_date = null;
      this.compKey++;
    },
  },
};
</script>
<style>
.radio .ant-col .ant-form-item-label {
  margin-right: 20px;
}

.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
</style>
