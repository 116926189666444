<template>
  <div>
    <a-modal
      v-model="visible"
      class="renew-form patient-modal"
      width="900px"
      title="Patient detail"
      :dialog-style="{ top: '60px' }"
      @ok="handleOk"
      :footer="null"
    >
      <div>
        <a-row :gutter="24">
          <a-col :span="4">
            <div
              style="display: flex; flex-direction: column; justify-content: start; align-items: center;"
            >
              <a-avatar
                size="large"
                :src="patient_image"
                style="height: 110px; width: 110px; font-size: 92px; border: 3px solid #CACACA; margin-bottom: 40px;"
              />
              <a-button
                type="primary"
                icon="upload"
                @click="handleUploadClick"
                :loading="loadingUploadBtn"
              >
                Upload
              </a-button>
              <input
                type="file"
                ref="fileInput"
                style="display: none"
                @change="handleFileChange"
              />
              <!-- <p style="display: flex; align-items: center; font-size: 18px">
                Upload
                <a-icon
                  type="upload"
                  style="margin-left: 10px"
                  @click="handleUploadClick"
                />
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  @change="handleFileChange"
                />
              </p> -->
            </div>
          </a-col>
          <a-col :span="20">
            <a-form-model ref="ruleForm" :model="form">
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-model-item
                    ref="patient_name"
                    label="नाम"
                    prop="patient_name"
                  >
                    <a-input
                      v-model="form.patient_name"
                      @blur="
                        () => {
                          $refs.patient_name.onFieldBlur();
                        }
                      "
                    />
                  </a-form-model-item>
                  <a-form-model-item label="जन्म मिति" prop="date_of_birth">
                    <a-date-picker
                      v-model="form.date_of_birth"
                      @change="onChange"
                      style="width: 100%;"
                    />
                  </a-form-model-item>
                  <a-form-model-item
                    ref="address"
                    label="ठेगाना"
                    prop="address"
                  >
                    <a-input
                      v-model="form.address"
                      @blur="
                        () => {
                          $refs.address.onFieldBlur();
                        }
                      "
                    />
                  </a-form-model-item>
                  <a-form-model-item label="प्रमुख डाक्टर" prop="doctor_id">
                    <a-input
                      disabled
                      v-model="form.doctor_id"
                      @blur="
                        () => {
                          $refs.patient_name.onFieldBlur();
                        }
                      "
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="लिंग" prop="sex">
                    <a-select
                      v-model="form.sex"
                      placeholder="please select your sex"
                    >
                      <a-select-option value="male">
                        Male
                      </a-select-option>
                      <a-select-option value="female">
                        Female
                      </a-select-option>
                      <a-select-option value="n/a">
                        N/A
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item
                    label="सम्पर्क नम्बर"
                    prop="contact_number"
                  >
                    <a-input-number
                      v-model="form.contact_number"
                      :min="9000000000"
                      style="width: 100%;"
                    />
                  </a-form-model-item>
                  <a-form-model-item
                    label="आपतकालीन सम्पर्क नम्बर "
                    prop="emergency_contact_number"
                  >
                    <a-input-number
                      v-model="form.emergency_contact_number"
                      :min="9000000000"
                      style="width: 100%;"
                    />
                  </a-form-model-item>
                  <a-form-model-item label="सेवा प्रकार" prop="service_type">
                    <a-input
                      disabled
                      v-model="form.service_type"
                      @blur="
                        () => {
                          $refs.patient_name.onFieldBlur();
                        }
                      "
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-form-model-item style="text-align: right">
                <a-button
                  type="primary"
                  @click="onSubmit"
                  style="background-color: #2d67bd; border: #2d67bd"
                  :loading="loadingBtn"
                >
                  अद्यावधिक गर्नुहोस्
                </a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
import HospitalRevenue from "../../services/HospitalRevenue";
import { bus } from "../../router/index.js";
export default {
  data() {
    return {
      visible: false,
      loadingBtn: false,
      loadingUploadBtn: false,
      patient_image: "",
      form: {
        doctor_id: "",
        health_care_number: null,
        patient_name: "",
        address: "",
        sex: undefined,
        date_of_birth: null,
        contact_number: null,
        emergency_contact_number: null,
        service_type: [],
      },
      patient_detail: {},
    };
  },
  methods: {
    showModal(data) {
      this.patient_detail = data;
      this.form = this.patient_detail;
      this.form.date_of_birth = data.date_of_birth;
      this.form.doctor_id = data.doctor.name;
      this.patient_image = data.image_url;
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          let formData = new FormData();
          for (let item in this.form) {
            formData.append(item, this.form[item]);
          }
          HospitalRevenue.updatePatient(this.patient_detail.id, formData).then(
            (res) => {
              this.loadingBtn = false;
              bus.$emit("fetchPatientData");
              this.$notification["success"]({
                message: "सफलतापूर्वक डेटा अद्यावधिक भयो",
                description: "Data updated successfully",
              });
              this.visible = false;
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
    },
    handleChange(value) {},
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOptionDoctor(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleUploadClick() {
      // Trigger a click event on the hidden file input element
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.loadingUploadBtn = true;
      const selectedFile = event.target.files[0];
      const formData = new FormData();
      formData.append("id", this.patient_detail.id);
      formData.append("image", selectedFile);
      HospitalRevenue.uploadPatientData(formData).then((res) => {
        // console.log("Patient image added");
        this.loadingUploadBtn = false;
      });
    },
  },
};
</script>
<style>
.renew-form .ant-modal .ant-modal-header {
  background-color: #07c400;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.renew-form .ant-modal-title {
  font-weight: bold !important;
  font-size: 1.2rem !important;
  color: #fff !important;
}
.patient-modal .ant-table-row:nth-child(even) {
  background-color: #f5f5f5;
}
.patient-modal .ant-table-thead > tr > th {
  background: #d0edd0 !important;
  padding: 10px;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.business-taxss .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}
.doctor-detail {
  display: flex;
  justify-content: space-between;
}
.doctor-detail-title {
  color: #5b82be;
  font-weight: 600;
}
.more-detail {
  border: none;
}
.patient-modal .ant-form-item-label {
  line-height: 20px;
}
</style>
