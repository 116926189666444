<template>
  <div>
    <div class="add-item">
      <h4>Medicinal items</h4>
      <a-button class="editable-add-btn" @click="handleAdd">
        Add
      </a-button>
    </div>
    <a-table
      class="item-table"
      :data-source="dataSource"
      :columns="columns"
      :pagination="false"
    >
      <template slot="name" slot-scope="text, record">
        <editable-cell
          :text="text"
          @change="onCellChange(record.key, 'name', $event)"
        />
      </template>
      <!-- Slot for 'age' column -->
      <template slot="qty" slot-scope="text, record">
        <editable-cell
          :text="text"
          @change="onCellChange(record.key, 'qty', $event)"
        />
      </template>
      <!-- Slot for 'address' column -->
      <template slot="unit_price" slot-scope="text, record">
        <editable-cell
          :text="text"
          @change="onCellChange(record.key, 'unit_price', $event)"
        />
      </template>
      <template slot="amount" slot-scope="text, record">
        {{ Number(record.qty) * Number(record.unit_price) }}
      </template>
      <!-- Slot for 'operation' column -->
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="dataSource.length"
          title="Sure to delete?"
          @confirm="() => onDelete(record.key)"
        >
          <a href="javascript:;">Delete</a>
        </a-popconfirm>
      </template>
      <template slot="footer">
        <a-row :gutter="24">
          <a-col :span="12" style="display: flex; float: left">
            <a-popover v-model="visible" trigger="click">
              <a slot="content">
                <a-input placeholder="Add Discount( % )" v-model="discount" />
                <a-button
                  type="primary"
                  ghost
                  @click="hide"
                  style="margin-top: 5px"
                  >Add</a-button
                >
                <a-button @click="resetDiscount" style="margin-top: 5px"
                  >Reset</a-button
                >
              </a>
              <a-button type="primary" ghost>
                <a-icon type="percentage" />Add Discount
              </a-button>
            </a-popover>
          </a-col>
          <a-col :span="12">
            <a-row>
              <a-col :span="8" :offset="8">Sub Total</a-col>
              <a-col :span="8">{{ calculateSubTotal }}</a-col>
            </a-row>
            <a-row>
              <a-col :span="8" :offset="8">Discount</a-col>
              <a-col :span="8">Rs. {{ calculateDiscount }}</a-col>
            </a-row>
            <a-row>
              <a-col :span="8" :offset="8">Total tax</a-col>
              <a-col :span="8">Rs. {{ calculateTax }}</a-col>
            </a-row>
            <a-divider
              class="bill-line"
              type="horizontal"
              style="margin: 4px 0; width: 55%; min-width: 55%; margin-right: 0; float: right"
            />
            <a-row style="font-size: 16px">
              <a-col
                :span="8"
                :offset="8"
                style="font-weight: bold; color: rgb(0,0,0,0.8)"
                >Total</a-col
              >
              <a-col :span="8" style="font-weight: bold; color: rgb(0,0,0,0.8)"
                >Rs. {{ calculateTotalPrice }}</a-col
              >
            </a-row>
          </a-col>
        </a-row>
      </template>
    </a-table>
  </div>
</template>
<script>
import HospitalRevenue from "../../services/HospitalRevenue";
import { bus } from "../../router/index.js";
const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
            type="check"
            class="editable-cell-icon-check"
            @click="check"
          />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
export default {
  props: ["vat", "forms_values"],
  components: {
    EditableCell,
  },
  computed: {
    calculateTotalPrice() {
      return (
        this.calculateSubTotal - this.calculateDiscount + this.calculateTax
      );
    },
    calculateSubTotal() {
      let total = null;
      for (let i = 0; i < this.dataSource.length; i++) {
        // console.log(this.dataSource[i].qty, this.dataSource[i].amount);
        total =
          total +
          Number(this.dataSource[i].qty) *
            Number(this.dataSource[i].unit_price);
      }
      return total;
    },
    calculateDiscount() {
      let discount_price = null;
      if (this.discount == null) {
        discount_price = 0;
      } else {
        discount_price = (this.discount * this.calculateSubTotal) / 100;
      }
      return discount_price;
    },
    calculateTax() {
      if (this.vat == 1) {
        let taxValue = 0.13 * this.calculateSubTotal;
        let formattedNumber = taxValue.toFixed(3);
        this.tax = Number(formattedNumber);
      } else if (this.vat == 0 || this.vat.length == 0) {
        this.tax = 0;
      }
      return this.tax;
    },
  },
  // watch: {
  //   vat: function(newVal) {
  //     if (this.vat == 1) {
  //       this.tax = 0.13 * this.calculateSubTotal;
  //     } else if (this.vat == 0 || this.vat.length == 0) {
  //       this.tax = 0;
  //     }
  //   },
  // },
  data() {
    return {
      visible: false,
      discount: null,
      tax: null,
      dataSource: [
        {
          key: "0",
          name: "Operation",
          qty: "1",
          unit_price: "10",
          amount: null,
        },
        {
          key: "1",
          name: "ENT",
          qty: "1",
          unit_price: "100",
          amount: null,
        },
      ],
      count: 2,
      columns: [
        {
          title: "S.N",
          dataIndex: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "Name/Descrption",
          dataIndex: "name",
          width: "25%",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Qty",
          dataIndex: "qty",
          scopedSlots: { customRender: "qty" },
        },
        {
          title: "Unit Price",
          dataIndex: "unit_price",
          scopedSlots: { customRender: "unit_price" },
        },
        {
          title: "Amount",
          dataIndex: "amount",
          scopedSlots: { customRender: "amount" },
        },
        {
          title: "",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
    };
  },
  methods: {
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter((item) => item.key !== key);
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        name: "",
        qty: "",
        unit_price: "",
        amount: null,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    hide() {
      this.visible = false;
    },
    resetDiscount() {
      this.discount = null;
      this.visible = false;
    },
    sendTableData(loadingBillPrepare) {
      let loadingBillButton = loadingBillPrepare;
      // console.log("Table data here: ", this.dataSource);
      // console.log("Discount: ", this.calculateDiscount);
      // console.log("Tax: ", this.calculateTax);
      // console.log("Total before: ", this.calculateSubTotal);
      // console.log("Total after: ", this.calculateTotalPrice);
      const allTableData = {
        // items: [],
        discount: null,
        tax: null,
        total_before: null,
        total_after: null,
      };
      // allTableData.items = this.dataSource;
      allTableData.discount = this.calculateDiscount;
      allTableData.tax = this.calculateTax;
      allTableData.total_before = this.calculateSubTotal;
      allTableData.total_after = this.calculateTotalPrice;
      const formData = new FormData();
      for (const key in allTableData) {
        formData.append(key, allTableData[key]);
      }
      this.dataSource.forEach((obj, index) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            formData.append(`items[${index}][${key}]`, obj[key]);
          }
        }
      });
      formData.append("issued_date", this.forms_values.issue_date);
      formData.append("issued_by", this.forms_values.issue_by);
      formData.append("patient_id", this.forms_values.patient_id);
      formData.append("vat_applied", this.forms_values.vat);

      for (const [key, value] of formData) {
        // console.log(`${key}: ${value}`);
      }
      HospitalRevenue.storePatientBill(formData)
        .then((res) => {
          loadingBillButton = false;
          bus.$emit("loadingBill", loadingBillButton);
          HospitalRevenue.viewPatientBill()
            .then((res) => {})
            .catch((err) => {
              console.log("error in fetching bill: ", err);
            });
        })
        .catch((err) => {
          loadingBillButton = false;
          bus.$emit("loadingBill", loadingBillButton);
          console.log("Cannot store bill");
        });
    },
  },
};
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.item-table .ant-table-footer {
  background: rgba(250, 250, 250, 0.5);
  text-align: end;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.5);
}
.add-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
