<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="make dynamic bill name here"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <div slot="pdf-content" class="whole-pdf">
        <!--display: flex;
          
        Divide your content into section, this pdf-item will
        be the element that it's content will not be separated
        in the paginating process. ex. <h4> and <span> wont be separated.
    -->
        <div class="pdfHeader" style="margin: 0 auto;">
          <div class="pdfHead">
            <div class="leftHeader">
              <span>
                <img
                  src="/images/Emblem.png"
                  alt="test_image"
                  class="header_img"
                />
              </span>
              <h3
                style="font-weight: bold; font-size: 15px; color: rgb(0,0,0,0.8)"
              >
                खिजीदेम्वा गाउँपालिका
              </h3>
              <h4
                style="font-weight: bold; font-size: 15px; color: rgb(0,0,0,0.8)"
              >
                सामुदायिक अस्पताल
              </h4>
            </div>
            <div
              class="rightHeader"
              style="display: flex; flex-direction: column; text-align: right;"
            >
              <h3 style="color: #ABABAB; font-weight: bold; font-size: 14px;">
                Invoice
              </h3>
              <h4
                style="font-weight: bold; font-size: 15px; color: rgb(0,0,0,0.8)"
              >
                Invoice No: {{ patientBill.bill[0].invoice_number }}
              </h4>
              <h4 style="color: #ABABAB; font-weight: bold; font-size: 15px;">
                Date issued: {{ patientBill.bill[0].issued_date }}
              </h4>
            </div>
          </div>
        </div>
        <div class="pdfBody">
          <h4 style="color: #5b82be; font-weight: 600; font-size: 15px">
            Patient
          </h4>

          <div class="body1">
            <div class="leftBody1">
              <h3
                style="font-weight: bold; font-size: 15px; color: rgb(0,0,0,0.8)"
              >
                {{ patientBill.patient_name }}
              </h3>
              <h4 style="color: #ABABAB; font-weight: bold; font-size: 15px;">
                {{ patientBill.address }}
              </h4>
              <h4 style="color: #ABABAB; font-weight: bold; font-size: 15px;">
                Examined by: Dr. {{ patientBill.doctor.name }}
              </h4>
            </div>
            <div class="rightBody1">
              <h3
                style="font-weight: bold; font-size: 15px; color: rgb(0,0,0,0.8)"
              >
                Issued by: {{ patientBill.bill[0].issued_by }}
              </h3>
              <h4 style="color: #ABABAB; font-weight: bold; font-size: 15px;">
                {{ patientBill.doctor.hospital_name }}
              </h4>
              <!-- <h4 style="color: #ABABAB; font-weight: bold; font-size: 15px;">
                Bhaktapur-01, Nepal
              </h4> -->
            </div>
          </div>
          <div class="body2">
            <h4
              style="font-size:15px ;font-weight: bold; margin: 14px 0; margin-top: 40px; color: rgb(0,0,0,0.8)"
            >
              Items
            </h4>
            <div class="itemList">
              <a-table
                :columns="columns"
                :data-source="allItems"
                :pagination="false"
              >
                <a slot="name" slot-scope="text">{{ text }}</a>
                <template slot="amount" slot-scope="text, record">
                  {{ Number(record.qty) * Number(record.unit_price) }}
                </template>
                <template slot="tags" slot-scope="text, record">
                  {{ record.service_type }}
                </template>
              </a-table>
              <div class="subTotal">
                <div>
                  <span class="totalText">Sub Total</span>
                  <span>Rs. {{ patientBill.bill[0].total_before }}</span>
                </div>
                <div>
                  <span class="totalText">Discount</span>
                  <span>Rs. {{ patientBill.bill[0].product_discount }}</span>
                </div>
                <div>
                  <span class="totalText">Total tax</span>
                  <span>Rs. {{ patientBill.bill[0].product_tax }}</span>
                </div>
                <div>
                  <span
                    class="totalText"
                    style="font-weight: bold; color: rgb(0,0,0,0.8)"
                  >
                    Total
                  </span>
                  <span style="font-weight: bold; color: rgb(0,0,0,0.8)">
                    Rs. {{ patientBill.bill[0].total_after }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pdfFooter">
          <a-divider type="horizontal" />
          <div class="footer">
            <span>2023 &copy; Cellapp</span>
            <img src="/images/SPLogo.png" class="footer-img" />
            <span
              >Powered by <span style="color: #5b82be;">SmartPalika</span></span
            >
          </div>
        </div>
      </div>
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";

const columns = [
  {
    title: "Name/Descrption",
    dataIndex: "name",
    key: "name",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Unit Price",
    dataIndex: "unit_price",
    key: "unit_price",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "Service Type",
    key: "tags",
    dataIndex: "tags",
    scopedSlots: { customRender: "tags" },
  },
];

const data = [];
const testData = [
  {
    key: "1",
    name: "Cetamol",
    qty: 3,
    unit_price: "10",
    tags: ["Primary Care"],
    amount: 30,
  },
  {
    key: "2",
    name: "Operation",
    qty: 0,
    unit_price: "500000",
    tags: ["Advanced Diagnostic"],
    amount: 500000,
  },
  {
    key: "3",
    name: "Tusk D",
    qty: 2,
    unit_price: "160",
    tags: ["Primary Care"],
    amount: 320,
  },
];
export default {
  data() {
    return {
      allItems: [],
      data,
      columns,
      patientBill: {
        bill: [
          {
            items: [],
          },
        ],
        doctor: {},
      },
    };
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    generateReport(data) {
      this.patientBill = { ...data };
      this.allItems = this.patientBill.bill[0].items;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
<style>
.pdfHeader,
.pdfBody {
  margin: 30px;
}

.pdfHead {
  margin: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-family: "Rubik", sans-serif;
}
.header_img {
  width: 40px;
  margin-bottom: 5px;
}
.footer-img {
  width: 27px;
}
.pdfBody {
  height: 200px;
}
.pdfBody .body2 {
  margin-bottom: 400px;
}
.item-list {
  margin-bottom: 50px;
}
.body1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subTotal {
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: 30px;
}
.totalText {
  margin-right: 5px;
  width: 100px;
  font-weight: bold;
  color: grey;
}
.pdfFooter {
  margin-top: 670px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
}
</style>
