<template>
  <div>
    <a-modal
      v-model="visible"
      title="Basic Modal"
      @ok="handleOk"
      :footer="null"
    >
      <div>
        <a-menu v-model="current" mode="horizontal">
          <a-menu-item key="mail"> <a-icon type="user" />Detail </a-menu-item>
          <a-menu-item key="app"> <a-icon type="file" />Files </a-menu-item>
        </a-menu>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      current: ["mail"],
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
  },
};
</script>
