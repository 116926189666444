import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import axiosInstance from './plugins/axios'
import 'ant-design-vue/dist/antd.css'
import VNepaliDatePicker from 'v-nepalidatepicker'
import VueRouter from 'vue-router'
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Checkbox,
  Input,
  Select,
  Form,
  Table,
  Icon,
  notification,
  FormModel,
  DatePicker,
  Layout,
  Card,
  Result,
  Spin,
  Modal,
  Radio,
  List,
  Popconfirm,
  message,
  Divider,
  Alert,
  Descriptions,
  Tabs,
  Tag,
  InputNumber,
  Progress,
  Steps,
  TimePicker,
  Upload,
  Popover,
  Menu,
  Dropdown,
  AutoComplete,
  Avatar,
  Collapse,
  Skeleton,
  Tooltip,
  TreeSelect,
  Badge,
  Timeline,
  Switch,
  Pagination,
} from 'ant-design-vue'

Vue.config.productionTip = false
// Vue.prototype.$http = axiosInstance

Vue.use(VNepaliDatePicker)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Breadcrumb)
Vue.use(Input)
Vue.use(Select)
Vue.use(Form)
Vue.use(Table)
Vue.use(Icon)
Vue.use(FormModel)
Vue.use(Layout)
Vue.use(Checkbox)
Vue.use(Card)
Vue.use(Result)
Vue.use(Spin)
Vue.use(Modal)
Vue.use(Radio)
Vue.use(List)
Vue.use(Popconfirm)
Vue.use(message)
Vue.use(Alert)
Vue.use(Divider)
Vue.use(Descriptions)
Vue.use(Tabs)
Vue.use(DatePicker)
Vue.use(Tag)
Vue.use(InputNumber)
Vue.use(Progress)
Vue.use(TimePicker)
Vue.use(Steps)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(AutoComplete)
Vue.use(Menu)
Vue.use(Avatar)
Vue.use(VueRouter)
Vue.use(Collapse)
Vue.use(Skeleton)
Vue.use(Tooltip)
Vue.use(TreeSelect)
Vue.use(Badge)
Vue.use(Timeline)
Vue.use(Switch)
Vue.use(Pagination)

new Vue({
  router,
  render: function (h) {
    return h(App)
  },
}).$mount('#app')
