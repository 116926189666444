<template>
  <div v-show="this.$route.path !== '/login'" class="cont">
    <div class="logoAndName">
       <div class="logoDiv">
                <img class="logo" src="../assets/logo.png" alt="">
            </div>
            <div class="nameDiv">
                <div class="sitename">खिजीदेम्वा गाउँपालिका</div>
                <h2>सामुदायिक अस्पताल</h2>
            </div> 
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  box-shadow: 5px 5px 5px #dedede;
}
.logoAndName {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoDiv,
.nameDiv {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px;
}
.nameDiv {
  flex-direction: column;
  align-items: start;
}
.logo {
  height: 90px;
  width: 90px;
  pointer-events: none;
}
.sitename {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
</style>
