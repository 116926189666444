<template>
  <div>
    <a-card title="चिकित्सक सूची" class="doctor-reg-table">
      <a-table :data-source="data" :columns="columns" v-if="data.length > 0">
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else-if="column.dataIndex === 'availability'">
            <span>{{ record.availability == 0 ? "छैन" : "छ" }}</span>
          </template>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <span slot="action" slot-scope="text, record">
          <a-icon
            type="eye"
            theme="filled"
            style="color: #3fc438"
            @click="openDoctorDetails(record)"
          />
          <a-divider type="vertical" />
          <a-icon type="edit" @click="editDoctorDetail(record)" />
          <a-divider type="vertical" />
          <a-icon type="delete" @click="deleteDoctorDetail(record.id)" />
        </span>
      </a-table>
      <div class="example" v-else>
        <a-spin />
      </div>

      <!-- Modals Here -->
      <doctor-modal ref="doc_details" />
    </a-card>
  </div>
</template>

<script>
import HospitalRevenue from "../../services/HospitalRevenue";
import DoctorModal from "./DoctorModal.vue";
import { bus } from "../../router/index.js";

export default {
  data() {
    return {
      visibleTable: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: "नं",
          dataIndex: "key",
          key: "key",
          width: "10%",
        },
        {
          title: "नाम",
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "उपलब्धता",
          dataIndex: "availability",
          key: "availability",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.availability
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "उपलब्धता समय",
          dataIndex: "working_time",
          key: "working_time",
          ellipsis: true,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.working_time
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: "कार्य",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
    DoctorModal,
  },
  created() {
    this.fetchDoctorData();
    bus.$on("fetchDoctorData", () => {
      this.fetchDoctorData();
    });
  },
  methods: {
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    openDoctorDetails(data) {
      this.$refs.doc_details.showModal(data);
    },
    //Data fetching from API here
    fetchDoctorData() {
      HospitalRevenue.fetchDoctor()
        .then((res) => {
          this.data = res.data.data.map((h, i) => {
            return {
              key: i + 1,
              ...h,
            };
          });
          this.visibleTable = true;
        })
        .catch((err) => {
          this.visibleTable = true;
          console.log("Error: ", err);
        });
    },
    editDoctorDetail(data) {
      const formRecord = { ...data };
      bus.$emit("editDoctorDetail", formRecord);
    },
    deleteDoctorDetail(id) {
      HospitalRevenue.deleteDoctor(id)
        .then((res) => {})
        .catch((err) => {
          console.log("Cannot delete doctor details: ", err);
        });
    },
  },
};
</script>
<style>
.doctor-reg-table .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}
.doctor-reg-table .ant-table-thead > tr > th {
  background: rgb(168, 228, 165, 0.5);
}
.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>
