<template>
  <div>
    <a-card title="चिकित्सक दर्ता फारम" class="doctor-reg-form">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        layout="vertical"
        :key="compKey"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="नाम" prop="name">
              <a-input v-model="form.name" placeholder="नाम" />
            </a-form-model-item>
            <a-form-model-item label="ठेगाना" prop="address">
              <a-input v-model="form.address" placeholder="ठेगाना" />
            </a-form-model-item>
            <a-form-model-item label="शहर" prop="city">
              <a-input v-model="form.city" placeholder="शहर" />
            </a-form-model-item>
            <a-form-model-item label="जन्म मिति" prop="date_of_birth">
              <VNepaliDatePicker
                calenderType="Nepali"
                placeholder="मिति चयन गर्नुहोस्"
                format="yyyy-mm-dd"
                classValue="nepalidatepicker"
                v-on:change="onChangeDate"
              />
            </a-form-model-item>
            <a-form-model-item label="लिंग" prop="gender">
              <a-select
                v-model="form.gender"
                placeholder="कृपया लिंग चयन गर्नुहोस्"
              >
                <a-select-option value="male">
                  Male
                </a-select-option>
                <a-select-option value="female">
                  Female
                </a-select-option>
                <a-select-option value="na">
                  Other
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="सम्पर्क नम्बर" prop="contact_number">
              <a-input-number
                v-model="form.contact_number"
                :min="9000000000"
                style="width: 100%;"
                placeholder="सम्पर्क नम्बर"
              />
            </a-form-model-item>
            <a-form-model-item label="अस्पतालको नाम" prop="hospital_name">
              <a-select
                v-model="form.hospital_name"
                placeholder="अस्पतालको नाम"
              >
                <a-select-option value="hospital1">
                  Hospital 1
                </a-select-option>
                <a-select-option value="hospital2">
                  Hospital 2
                </a-select-option>
                <a-select-option value="hospital3">
                  Hospital 3
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="काम गर्ने समय" prop="working_time">
              <div class="date-picker-container">
                <VNepaliDatePicker
                  calenderType="Nepali"
                  placeholder="सुरु समय"
                  format="yyyy-mm-dd"
                  style="width: 50%;"
                  classValue="nepalidatepicker"
                  v-on:change="onChangeStartTime"
                />
                <VNepaliDatePicker
                  calenderType="Nepali"
                  placeholder="अन्त्य समय"
                  format="yyyy-mm-dd"
                  style="width: 50%;"
                  classValue="nepalidatepicker"
                  v-on:change="onChangeEndTime"
                />
              </div>
            </a-form-model-item>
            <a-form-model-item label="उपलब्धता" prop="availability">
              <a-select v-model="form.availability" placeholder="उपलब्धता">
                <a-select-option value="1">
                  छ
                </a-select-option>
                <a-select-option value="0">
                  छैन
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="specifications"
              label="विशेषज्ञता"
              prop="specifications"
            >
              <a-input v-model="form.specifications" placeholder="विशेषज्ञता" />
            </a-form-model-item>
            <a-form-model-item label="थप नोटहरू" prop="additional_notes">
              <a-input
                v-model="form.additional_notes"
                type="textarea"
                placeholder="थप नोटहरू"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item style="text-align: right">
          <a-button
            type="primary"
            @click="onSubmit"
            style="background-color: #2d67bd; border: #2d67bd"
            :loading="loadingBtn"
          >
            {{ update ? "अपडेट" : "थप्नुहोस्" }}
          </a-button>
          <a-button type="danger" style="margin-left: 10px;" @click="resetForm">
            रिसेट
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import HospitalRevenue from "../../services/HospitalRevenue";
import { bus } from "../../router/index.js";
import VNepaliDatePicker from "v-nepalidatepicker";

export default {
  components: {
    VNepaliDatePicker,
  },
  data() {
    return {
      loadingBtn: false,
      update: false,
      doctorID: null,
      form: {
        name: "",
        address: "",
        city: "",
        date_of_birth: null,
        gender: undefined,
        contact_number: null,
        hospital_name: undefined,
        working_time: [null, null],
        availability: undefined,
        specifications: "",
        additional_notes: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input Activity name",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "Please select Activity zone",
            trigger: "change",
          },
        ],
        date1: [
          { required: true, message: "Please pick a date", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "Please select at least one activity type",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "Please select activity resource",
            trigger: "change",
          },
        ],
        additional_notes: [
          {
            required: true,
            message: "Please input activity form",
            trigger: "blur",
          },
        ],
      },
      compKey: 0,
    };
  },
  created() {
    bus.$on("editDoctorDetail", (data) => {
      this.update = true;
      this.addDoctorValues(data);
    });
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          const formData = new FormData();
          for (const vals in this.form) {
            formData.append(vals, this.form[vals]);
          }
          if (!this.update) {
            HospitalRevenue.storeDoctor(formData)
              .then((res) => {
                this.loadingBtn = false;
                bus.$emit("fetchDoctorData");
                this.$notification["success"]({
                  message: "सफलतापूर्वक डेटा थपियो",
                  description: "Data added successfully",
                });
                this.compKey++;
                this.resetForm();
              })
              .catch((err) => {
                console.log("Cannot store: ", err);
                this.loadingBtn = false;
                this.$notification["error"]({
                  message: "त्रुटि फेला पर्यो",
                  description: "Cannot add data",
                });
              });
          } else {
            HospitalRevenue.updateDoctor(this.doctorID, formData)
              .then((res) => {
                this.loadingBtn = false;
                bus.$emit("fetchDoctorData");
                this.$notification["success"]({
                  message: "सफलतापूर्वक डेटा अपडेट भयो",
                  description: "Data updated successfully",
                });
                this.update = false;
                this.doctorID = null;
                this.compKey++;
                this.resetForm();
              })
              .catch((err) => {
                console.log("Cannot store: ", err);
                this.loadingBtn = false;
                this.$notification["error"]({
                  message: "त्रुटि फेला पर्यो",
                  description: "Cannot update data",
                });
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.update = false;
      this.compKey++;
    },
    onChangeStartTime(newDate) {
      this.form.working_time[0] = newDate;
    },
    onChangeEndTime(newDate) {
      this.form.working_time[1] = newDate;
    },
    onChangeDate(newDate) {
      this.form.date_of_birth = newDate;
    },
    addDoctorValues(data) {
      this.form = data;
      this.form.working_time = data.working_time.split(",");
      this.form.gender = data.gender.toString();
      this.form.availability = data.availability.toString();
      this.doctorID = data.id;
    },
  },
};
</script>

<style>
.doctor-reg-form .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}

.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.date-picker-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
</style>
