<template>
  <div>
    <a-row :span="24" :gutter="16">
      <a-col :span="12">
        <doctor-form />
      </a-col>
      <a-col :span="12">
        <doctor-table />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import doctorForm from "./doctorForm.vue";
import doctorTable from "./doctorTable.vue";

export default {
  components: {
    doctorForm,
    doctorTable,
  },
};
</script>
