var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{staticClass:"renew-form patient-modal",attrs:{"width":"900px","title":"Patient detail","dialog-style":{ top: '60px' },"footer":null},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":4}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"start","align-items":"center"}},[_c('a-avatar',{staticStyle:{"height":"110px","width":"110px","font-size":"92px","border":"3px solid #CACACA","margin-bottom":"40px"},attrs:{"size":"large","src":_vm.patient_image}}),_c('a-button',{attrs:{"type":"primary","icon":"upload","loading":_vm.loadingUploadBtn},on:{"click":_vm.handleUploadClick}},[_vm._v(" Upload ")]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.handleFileChange}})],1)]),_c('a-col',{attrs:{"span":20}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{ref:"patient_name",attrs:{"label":"नाम","prop":"patient_name"}},[_c('a-input',{on:{"blur":() => {
                        _vm.$refs.patient_name.onFieldBlur();
                      }},model:{value:(_vm.form.patient_name),callback:function ($$v) {_vm.$set(_vm.form, "patient_name", $$v)},expression:"form.patient_name"}})],1),_c('a-form-model-item',{attrs:{"label":"जन्म मिति","prop":"date_of_birth"}},[_c('a-date-picker',{staticStyle:{"width":"100%"},on:{"change":_vm.onChange},model:{value:(_vm.form.date_of_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_of_birth", $$v)},expression:"form.date_of_birth"}})],1),_c('a-form-model-item',{ref:"address",attrs:{"label":"ठेगाना","prop":"address"}},[_c('a-input',{on:{"blur":() => {
                        _vm.$refs.address.onFieldBlur();
                      }},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('a-form-model-item',{attrs:{"label":"प्रमुख डाक्टर","prop":"doctor_id"}},[_c('a-input',{attrs:{"disabled":""},on:{"blur":() => {
                        _vm.$refs.patient_name.onFieldBlur();
                      }},model:{value:(_vm.form.doctor_id),callback:function ($$v) {_vm.$set(_vm.form, "doctor_id", $$v)},expression:"form.doctor_id"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"label":"लिंग","prop":"sex"}},[_c('a-select',{attrs:{"placeholder":"please select your sex"},model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}},[_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")]),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v(" Female ")]),_c('a-select-option',{attrs:{"value":"n/a"}},[_vm._v(" N/A ")])],1)],1),_c('a-form-model-item',{attrs:{"label":"सम्पर्क नम्बर","prop":"contact_number"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":9000000000},model:{value:(_vm.form.contact_number),callback:function ($$v) {_vm.$set(_vm.form, "contact_number", $$v)},expression:"form.contact_number"}})],1),_c('a-form-model-item',{attrs:{"label":"आपतकालीन सम्पर्क नम्बर ","prop":"emergency_contact_number"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":9000000000},model:{value:(_vm.form.emergency_contact_number),callback:function ($$v) {_vm.$set(_vm.form, "emergency_contact_number", $$v)},expression:"form.emergency_contact_number"}})],1),_c('a-form-model-item',{attrs:{"label":"सेवा प्रकार","prop":"service_type"}},[_c('a-input',{attrs:{"disabled":""},on:{"blur":() => {
                        _vm.$refs.patient_name.onFieldBlur();
                      }},model:{value:(_vm.form.service_type),callback:function ($$v) {_vm.$set(_vm.form, "service_type", $$v)},expression:"form.service_type"}})],1)],1)],1),_c('a-form-model-item',{staticStyle:{"text-align":"right"}},[_c('a-button',{staticStyle:{"background-color":"#2d67bd","border":"#2d67bd"},attrs:{"type":"primary","loading":_vm.loadingBtn},on:{"click":_vm.onSubmit}},[_vm._v(" अद्यावधिक गर्नुहोस् ")])],1)],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }