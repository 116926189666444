<template>
  <div>
    <a-row :span="24" :gutter="16">
      <a-col :span="12">
        <patient-form />
      </a-col>
      <a-col :span="12">
        <patient-table />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import PatientForm from "./PatientForm.vue";
import PatientTable from "./PatientTable.vue";

export default {
  components: {
    PatientForm,
    PatientTable,
  },
};
</script>
