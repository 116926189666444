<template>
  <div>
    <div v-if="loading" class="spinner"><a-spin /></div>
    <div v-else class="dashboard">
      <div class="stat-box">
        <h3>{{ doctors.length }}</h3>
        <h2>कुल डाक्टर</h2>
      </div>
      <div class="stat-box">
        <h3>{{ maleDoctorCount }}</h3>
        <h2>पुरुष डाक्टर</h2>
      </div>
      <div class="stat-box">
        <h3>{{ femaleDoctorCount }}</h3>
        <h2>महिला डाक्टर</h2>
      </div>
      <div class="stat-box">
        <h3>{{ otherDoctorCount }}</h3>
        <h2>अन्य डाक्टर</h2>
      </div>
      <div class="stat-box">
        <h3>{{ patients.length }}</h3>
        <h2>कुल बिरामी</h2>
      </div>
      <div class="stat-box">
        <h3>{{ malePatientCount }}</h3>
        <h2>पुरुष बिरामी</h2>
      </div>
      <div class="stat-box">
        <h3>{{ femalePatientCount }}</h3>
        <h2>महिला बिरामी</h2>
      </div>
      <div class="stat-box">
        <h3>{{ otherPatientCount }}</h3>
        <h2>अन्य बिरामी</h2>
      </div>
      <div class="stat-box">
        <h3>{{ primaryCareCount }}</h3>
        <h2>प्राथमिक हेरचाह बिरामी</h2>
      </div>
      <div class="stat-box">
        <h3>{{ emergencyCareCount }}</h3>
        <h2>आपतकालीन हेरचाह बिरामी</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HospitalRevenue from "../../services/HospitalRevenue";

export default {
  data() {
    return {
      doctors: [],
      patients: [],
      loading: true,
      error: null,
    };
  },
  created() {
    this.fetchDoctorData();
    this.fetchPatientDetail();
  },
  computed: {
    maleDoctorCount() {
      return this.doctors.filter((doctor) => doctor.gender === "male").length;
    },
    femaleDoctorCount() {
      return this.doctors.filter((doctor) => doctor.gender === "female").length;
    },
    otherDoctorCount() {
      return this.doctors.filter(
        (doctor) => doctor.gender !== "male" && doctor.gender !== "female"
      ).length;
    },
    malePatientCount() {
      return this.patients.filter((patient) => patient.sex === "male").length;
    },
    femalePatientCount() {
      return this.patients.filter((patient) => patient.sex === "female").length;
    },
    otherPatientCount() {
      return this.patients.filter(
        (patient) => patient.sex !== "male" && patient.sex !== "female"
      ).length;
    },
    primaryCareCount() {
      return this.patients.filter(
        (patient) => patient.service_type === "Primary Care"
      ).length;
    },
    emergencyCareCount() {
      return this.patients.filter(
        (patient) => patient.service_type === "Emergency Care"
      ).length;
    },
  },
  methods: {
    fetchDoctorData() {
      HospitalRevenue.fetchDoctor()
        .then((res) => {
          this.doctors = res.data.data.map((h, i) => {
            return {
              key: i + 1,
              ...h,
            };
          });
          this.loading = false;
        })
        .catch((err) => {
          this.error = err.message || "An error occurred";
          this.loading = false;
        });
    },
    fetchPatientDetail() {
      HospitalRevenue.fetchPatient()
        .then((res) => {
          this.patients = res.data.data.map((h, i) => {
            return {
              key: i + 1,
              ...h,
            };
          });
          this.loading = false;
        })
        .catch((err) => {
          this.error = err.message || "An error occurred";
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.dashboard {
  display: flex;
  gap: 16px;
  padding: 16px;
  height: 70vh;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.stat-box {
  flex: 1 1 200px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 130px;
  transition: all 0.3s ease;
}

.stat-box:hover {
  transform: scale(1.02);
  border-color: #339af0;
}

.stat-box h3 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.stat-box h2 {
  margin: 0;
  font-size: 16px;
  color: #777;
}
</style>
