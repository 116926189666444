<template>
  <a-card title="बिरामी दर्ता फारम" class="hospital-rev-form">
    <a slot="extra">
      <a-switch
        checked-children="Rural"
        un-checked-children="Basic"
        v-model="form.whichHospital"
      />
    </a>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :key="compKey"
    >
      <a-form-model-item label="दर्ता मिति र समय" required prop="date">
        <VNepaliDatePicker
          calenderType="Nepali"
          placeholder="मिति चयन गर्नुहोस्"
          format="yyyy-mm-dd"
          classValue="nepalidatepicker"
          v-on:change="onChange"
        />
      </a-form-model-item>
      <a-form-model-item label="स्वास्थ्य सेवा नम्बर">
        <a-input-number
          id="inputNumber"
          v-model="form.health_care_number"
          :min="1"
          style="width: 100%;"
          placeholder="स्वास्थ्य सेवा नम्बर"
        />
      </a-form-model-item>
    </a-form-model>
    <a-divider class="divider">Patient Information</a-divider>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      layout="vertical"
      :key="compKey"
    >
      <a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item
              ref="patient_name"
              label="नाम"
              prop="patient_name"
            >
              <a-input
                v-model="form.patient_name"
                placeholder="नाम"
                @blur="
                  () => {
                    $refs.patient_name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="लिंग" prop="sex">
              <a-select
                v-model="form.sex"
                placeholder="कृपया लिंग चयन गर्नुहोस्"
              >
                <a-select-option value="male">
                  Male
                </a-select-option>
                <a-select-option value="female">
                  Female
                </a-select-option>
                <a-select-option value="n/a">
                  Other
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="जन्म मिति" prop="date_of_birth">
              <VNepaliDatePicker
                calenderType="Nepali"
                placeholder="मिति चयन गर्नुहोस्"
                format="yyyy-mm-dd"
                classValue="nepalidatepicker"
                v-on:change="onChangeDate"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="सम्पर्क नम्बर" prop="contact_number">
              <a-input-number
                v-model="form.contact_number"
                :min="9000000000"
                style="width: 100%;"
                placeholder="सम्पर्क नम्बर"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item ref="address" label="ठेगाना" prop="address">
              <a-input
                v-model="form.address"
                placeholder="ठेगाना"
                @blur="
                  () => {
                    $refs.address.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item label="प्रमुख डाक्टर" prop="doctor_id">
              <a-select
                v-model="form.doctor_id"
                show-search
                option-filter-prop="children"
                style="width: 100%"
                :filter-option="filterOptionDoctor"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="handleChange"
                placeholder="कृपया प्रमुख डाक्टर चयन गर्नुहोस्"
              >
                <a-select-option
                  v-for="doctor in available_doctor"
                  :key="doctor.id"
                  :value="doctor.id"
                >
                  {{ doctor.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="आपतकालीन सम्पर्क नम्बर "
              prop="emergency_contact_number"
            >
              <a-input-number
                v-model="form.emergency_contact_number"
                :min="9000000000"
                style="width: 100%;"
                placeholder="आपतकालीन सम्पर्क नम्बर"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-row>
      <a-divider class="divider">Service Type</a-divider>
      <a-form-model-item>
        <a-select
          mode="multiple"
          show-search
          placeholder="सेवा प्रकारहरू छान्नुहोस्"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="filterOption"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          v-model="form.service_type"
        >
          <a-select-option
            v-for="option in selectOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item style="text-align: right">
        <a-button
          type="primary"
          @click="onSubmit"
          style="background-color: #2d67bd; border: #2d67bd"
          :loading="loadingBtn"
        >
          थप्नुहोस्
        </a-button>
        <a-button type="danger" style="margin-left: 10px;" @click="resetForm">
          रिसेट
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>
<script>
import HospitalRevenue from "../../services/HospitalRevenue";
import { bus } from "../../router/index.js";
import VNepaliDatePicker from "v-nepalidatepicker";

export default {
  components: {
    VNepaliDatePicker,
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      other: "",
      available_doctor: [],
      loadingBtn: false,
      compKey: 0,
      form: {
        doctor_id: null,
        health_care_number: null,
        patient_name: "",
        address: "",
        sex: undefined,
        date: undefined,
        date_of_birth: undefined,
        contact_number: null,
        emergency_contact_number: null,
        whichHospital: true,
        service_type: [],
      },
      rules: {
        health_care_number: [
          {
            required: true,
            message: "Please input the Health Care Number",
          },
        ],
        patient_name: [
          {
            required: true,
            message: "Please input Patient patient_name",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "Please input address" }],
        sex: [
          {
            required: true,
            message: "Please select Sex",
            trigger: "change",
          },
        ],
        date_of_birth: [
          {
            required: true,
            message: "Please pick your date_of_birth",
            trigger: "change",
          },
        ],
        date: [
          { required: true, message: "Please pick a date", trigger: "change" },
        ],
        emergency_contact_number: [
          {
            required: true,
            message: "Please enter Emergency Number",
          },
          {
            pattern: /^\d{10}$/,
            message: "Please enter Valid Number",
          },
        ],
      },
    };
  },
  computed: {
    selectOptions() {
      if (this.form.whichHospital) {
        // Return options for "rural" list
        return [
          { value: "Primary Care", label: "Primary Care" },
          { value: "Emergency Care", label: "Emergency Care" },
          { value: "Diagnostic Services", label: "Diagnostic Services" },
          {
            value: "Maternity and Obstetrics",
            label: "Maternity and Obstetrics",
          },
        ];
      } else {
        // Return options for "basic" list
        return [
          {
            value: "Advanced Diagnostic and Imaging",
            label: "Advanced Diagnostic and Imaging",
          },
          {
            value: "Intensive Care Units (ICUs)",
            label: "Intensive Care Units (ICUs)",
          },
          {
            value: "Advanced Obstetrics and Gynecology",
            label: "Advanced Obstetrics and Gynecology",
          },
          { value: "Teaching and Research", label: "Teaching and Research" },
        ];
      }
    },
  },
  watch: {
    "form.whichHospital": function(newVal) {
      this.form.service_type = [];
    },
  },
  created() {
    this.availableDoctor();
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          let formData = new FormData();
          for (let item in this.form) {
            formData.append(item, this.form[item]);
          }
          // for (let [key, value] of formData.entries()) {
          //   console.log(key, value);
          // }
          HospitalRevenue.storePatient(formData)
            .then((res) => {
              this.loadingBtn = false;
              bus.$emit("fetchPatientData");
              this.$notification["success"]({
                message: "सफलतापूर्वक डेटा थपियो",
                description: "Data added successfully",
              });
              this.compKey++;
              this.resetForm();
            })
            .catch((err) => {
              this.loadingBtn = false;
              console.log("Cannot store Patient details: ", err);
              this.$notification["error"]({
                message: "त्रुटि फेला पर्यो",
                description: "Cannot add data",
              });
            });
        } else {
          this.loadingBtn = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.form.service_type = [];
      this.compKey++;
      this.form.date = undefined;
      this.form.date_of_birth = undefined;
      this.form.health_care_number = null;
    },
    onChange(newDate) {
      this.form.date = newDate;
    },
    onChangeDate(newDate) {
      this.form.date_of_birth = newDate;
    },
    handleChange(value) {},
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOptionDoctor(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    //API's here
    availableDoctor() {
      HospitalRevenue.fetchDoctor().then((res) => {
        this.available_doctor = res.data.data.filter((item) => {
          return item.availability == 1;
        });
      });
    },
  },
};
</script>
<style>
.hospital-rev-form .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}
.hospital-rev-form .divider {
  color: #2d67bd;
}
.hospital-rev-form .ant-switch {
  background-color: rgb(49 192 42);
}
.hospital-rev-form .ant-switch-checked {
  background-color: #2d67bd;
}

.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
</style>
