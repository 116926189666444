import axios from "../plugins/axios";

export default {
  storeDoctor(data) {
    return axios.post("/api/hospitalrevenue/doctor/store", data);
  },
  fetchDoctor() {
    return axios.get("/api/hospitalrevenue/doctor");
  },
  deleteDoctor(id) {
    return axios.post(`/api/hospitalrevenue/doctor/delete/${id}`);
  },
  updateDoctor(id, data) {
    return axios.post(`/api/hospitalrevenue/doctor/update/${id}`, data);
  },
  storePatient(data) {
    return axios.post("/api/hospitalrevenue/patient/store", data);
  },
  fetchPatient() {
    return axios.get("/api/hospitalrevenue/patient");
  },
  deletePatient(id) {
    return axios.post(`/api/hospitalrevenue/patient/delete/${id}`);
  },
  updatePatient(id, data) {
    return axios.post(`/api/hospitalrevenue/patient/update/${id}`, data);
  },
  uploadPatientData(data) {
    return axios.post(`/api/hospitalrevenue/patient/profile-image`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  storePatientBill(data) {
    return axios.post("/api/hospitalrevenue/bill/store", data);
  },
  viewPatientBill() {
    return axios.get("/api/hospitalrevenue/bill");
  },
  viewPatientBillById(id) {
    return axios.get(`/api/hospitalrevenue/patient/view/${id}`);
  },
};
