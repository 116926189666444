import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import Home from "../components/Home/index.vue";
import Login from "../views/Login.vue";
import patient from "../components/Patient/index.vue";
import doctor from "../components/Doctor/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/hospitalRevenue/patient",
    name: "patient",
    component: patient,
  },
  {
    path: "/hospitalRevenue/doctor",
    name: "doctor",
    component: doctor,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get("hospital_token");

  if (to.path === "/login" && token) {
    next("/");
  } else if (to.path !== "/login" && !token) {
    next("/login");
  } else {
    next();
  }
});

export default router;
