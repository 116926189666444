<template>
  <div class="cont">
    <div class="center-content">
      <a-card class="login-card">
        <div class="login-header">
          <img class="formLogo" src="../assets/logo.png" alt="Logo" />
          <h2>खिजीदेम्वा गाउँपालिका सामुदायिक अस्पताल</h2>
        </div>
        <a-form @submit="handleSubmit" class="login-form">
          <div class="flexFormItem">
            <a-icon type="mail" />
            <input
              class="formInput"
              v-model="email"
              placeholder="इमेल"
              prefix-icon="mail"
            />
          </div>
          <div class="flexFormItem">
            <a-icon type="lock" />
            <input
              :type="showPassword ? 'text' : 'password'"
              class="formInput"
              v-model="password"
              placeholder="पासवर्ड"
              prefix-icon="lock"
            />
            <a-icon
              v-if="password"
              :type="showPassword ? 'eye-invisible' : 'eye'"
              @click="togglePasswordVisibility"
              class="togglePasswordIcon"
            />
          </div>
          <a-button
            :loading="isLoading"
            type="primary"
            html-type="submit"
            class="login-button custom-button"
          >
            लग इन गर्नुहोस्
          </a-button>
        </a-form>
      </a-card>
    </div>

    <div class="footer">
      <p>
        Powered By:
        <a
          href="https://smartpalika.org/"
          target="_blank"
          style="color: #0ac400"
        >
          SmartPalika - स्मार्ट बन्दैछ नेपाल !
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { message } from "ant-design-vue";
import Auth from "../services/Auth.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      isLoading: false,
    };
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.isLoading = true;

      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      try {
        const res = await Auth.handleLogin(formData);
        const token = res.data.data.token;
        Cookies.set("hospital_token", token, { expires: 7 });
        await this.$router.push("/");
        message.success("लगइन सफल भयो।");
      } catch (error) {
        console.error("Login Error:", error);
        message.error("लग इनमा समस्या आयोको छ !");
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(90deg, #79d574 0%, #0ac400 100%); */
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.login-card {
  width: 360px;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-header {
  text-align: center;
  margin-bottom: 24px;
}

.formLogo {
  height: 120px;
  width: 120px;
  pointer-events: none;
}

h2 {
  margin: 8px 0px;
}

.flexFormItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 2px 8px;
}

.formInput {
  border: none;
  height: 40px;
  width: 220px;
}

.formInput:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.togglePasswordIcon {
  cursor: pointer;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
}

.login-form .login-button {
  width: 100%;
}

.login-button {
  background-color: #053b74;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  height: 40px;
  transition: background-color 0.3s;
}

.login-button:hover,
.login-button:focus,
.login-button:active {
  background-color: #013d7d;
  border: none;
}

.custom-button {
  background-color: #053b74;
  border-color: #013d7d;
}

.custom-button:hover,
.custom-button:focus,
.custom-button:active {
  background-color: #013d7d !important;
  border-color: #013d7d !important;
}

::placeholder {
  font-size: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
</style>
