<template>
  <div>
    <a-modal
      class="renew-form doctor-modal"
      width="900px"
      title="Doctor detail"
      :dialog-style="{ top: '60px' }"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleOk"
    >
      <a-row :gutter="24">
        <a-col :span="4">
          <div
            style="display: flex; flex-direction: column; justify-content: start; align-items: center;"
          >
            <a-avatar
              size="large"
              src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              style="height: 110px; width: 110px; font-size: 92px; border: 3px solid #CACACA; margin-bottom: 40px;"
            />
            <p style="display: flex; align-items: center; font-size: 18px">
              Upload <a-icon type="upload" style="margin-left: 10px" />
            </p>
          </div>
        </a-col>
        <a-col :span="10">
          <a-card class="more-detail">
            <a-row>
              <div class="doctor-detail">
                <p class="doctor-detail-title">नाम</p>
                <p style="font-weight: 600">{{ all_data.name }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">शहर</p>
                <p style="font-weight: 600">{{ all_data.city }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">ठेगाना</p>
                <p style="font-weight: 600">{{ all_data.address }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">सम्पर्क नम्बर</p>
                <p style="font-weight: 600">{{ all_data.contact_number }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">जन्म मिति</p>
                <p style="font-weight: 600">{{ all_data.date_of_birth }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">लिंग</p>
                <p style="font-weight: 600">{{ all_data.gender }}</p>
              </div>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="10">
          <a-card class="more-detail">
            <a-row>
              <div class="doctor-detail">
                <p class="doctor-detail-title">विशेषज्ञता</p>
                <p style="font-weight: 600">{{ all_data.specifications }}</p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">उपलब्धता</p>
                <p style="font-weight: 600">
                  {{ all_data.availability == 0 ? "छैन" : "छ" }}
                </p>
              </div>
              <div class="doctor-detail">
                <p class="doctor-detail-title">काम गर्ने समय</p>
                <p style="font-weight: 600">{{ all_data.working_time }}</p>
              </div>
              <div>
                <p class="doctor-detail-title">अतिरिक्त नोटहरू</p>
                <p style="font-weight: 600">{{ all_data.additional_notes }}</p>
              </div>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      all_data: {},
    };
  },
  methods: {
    showModal(data) {
      this.visible = true;
      this.all_data = data;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
  },
};
</script>
<style>
.renew-form .ant-modal .ant-modal-header {
  background-color: #07c400;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.renew-form .ant-modal-title {
  font-weight: bold !important;
  font-size: 1.2rem !important;
  color: #fff !important;
}
.doctor-modal .ant-table-row:nth-child(even) {
  background-color: #f5f5f5;
}
.doctor-modal .ant-table-thead > tr > th {
  background: #d0edd0 !important;
  padding: 10px;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.business-taxss .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}
.doctor-detail {
  display: flex;
  justify-content: space-between;
}
.doctor-detail-title {
  color: #5b82be;
  font-weight: 600;
}
.more-detail {
  border: none;
}
</style>
