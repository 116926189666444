var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',{staticClass:"doctor-reg-table",attrs:{"title":"चिकित्सक सूची"}},[(_vm.data.length > 0)?_c('a-table',{attrs:{"data-source":_vm.data,"columns":_vm.columns},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
              .toString()
              .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):(column.dataIndex === 'availability')?[_c('span',[_vm._v(_vm._s(record.availability == 0 ? "छैन" : "छ"))])]:[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-icon',{staticStyle:{"color":"#3fc438"},attrs:{"type":"eye","theme":"filled"},on:{"click":function($event){return _vm.openDoctorDetails(record)}}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-icon',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.editDoctorDetail(record)}}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteDoctorDetail(record.id)}}})],1)}}],null,false,2951917475)}):_c('div',{staticClass:"example"},[_c('a-spin')],1),_c('doctor-modal',{ref:"doc_details"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }