<template>
  <div>
    <a-card title="बिरामी सूची" class="hospital-revenue-table">
      <a-table :columns="columns" :data-source="data" v-if="visibleTable">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle">Name</span>
        <!-- <span slot="tags" slot-scope="tags">
          <a-tag
            v-for="(tag, i) in tags.split(',')"
            :key="i"
            :color="
              tag === 'हारेको'
                ? 'volcano'
                : tag.length > 5
                ? 'geekblue'
                : 'green'
            "
          >
            {{ tag.toUpperCase() }}
          </a-tag>
        </span> -->
        <span slot="action" slot-scope="text, record">
          <a-icon
            type="eye"
            theme="filled"
            style="color: #3fc438; font-size: 17px"
            @click="showPatientDetail(record)"
          />
          <a-divider type="vertical" />
          <a href="javascript:;" title="Prepare Bill">
            <a-icon
              type="money-collect"
              theme="filled"
              style="color: #4169e1; font-size: 17px"
              @click="openPrepareBillModal(record)"
            />
          </a>
          <a-divider type="vertical" />
          <a href="javascript:;" title="Generate Bill">
            <a-icon
              v-if="!record.loading"
              type="file-pdf"
              theme="filled"
              style="color: #fa1508; font-size: 17px"
              @click="openPdf(record)"
            />
            <a-spin v-else size="small" style="margin-top: 2px" />
          </a>
        </span>
      </a-table>
      <div class="example" v-else>
        <a-spin />
      </div>
      <!-- <main-modal ref="patientDetail" /> -->
      <patient-modal ref="patientDetail" />
      <prepare-bill ref="prepareBill" />
      <pdf ref="pdfHRT" />
    </a-card>
  </div>
</template>

<script>
import PrepareBill from "../Modals/PrepareBill.vue";
import pdf from "../Modals/pdf.vue";
import HospitalRevenue from "../../services/HospitalRevenue";
import { bus } from "../../router/index.js";
import PatientModal from "./PatientModal.vue";
import MainModal from "./MainModal.vue";

const columns = [
  {
    title: "नं",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "नाम",
    dataIndex: "patient_name",
    key: "patient_name",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "name" },
  },
  {
    title: "लिंग",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "ठेगाना",
    dataIndex: "address",
    key: "address",
  },
  // {
  //   title: "Service Type",
  //   key: "tags",
  //   dataIndex: "service_type",
  //   scopedSlots: { customRender: "tags" },
  // },
  {
    title: "कार्य",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "22%",
  },
];

export default {
  components: {
    MainModal,
    PatientModal,
    PrepareBill,
    pdf,
  },
  data() {
    return {
      visibleTable: false,
      data: [],
      formRecord: {},
      patientBillDetail: {},
      columns,
    };
  },
  created() {
    this.fetchPatientDetail();
    bus.$on("fetchPatientData", () => {
      this.fetchPatientDetail();
    });
  },
  methods: {
    showPatientDetail(data) {
      this.formRecord = { ...data };
      this.$refs.patientDetail.showModal(this.formRecord);
    },
    openPrepareBillModal(data) {
      this.$refs.prepareBill.showModal(data);
    },
    openPdf(record) {
      this.$set(record, "loading", true);
      const ID = Number(record.id);
      HospitalRevenue.viewPatientBillById(ID)
        .then((res) => {
          this.patientBillDetail = { ...res.data.data };
          this.$refs.pdfHRT.generateReport(this.patientBillDetail);
        })
        .catch((err) => {
          console.log("Error in pdf viewing: ", err);
        })
        .finally(() => {
          this.$set(record, "loading", false);
        });
    },
    //API's here
    fetchPatientDetail() {
      HospitalRevenue.fetchPatient()
        .then((res) => {
          this.data = res.data.data.map((h, i) => {
            return {
              key: i + 1,
              ...h,
              loading: false,
            };
          });
          this.visibleTable = true;
        })
        .catch((err) => {
          this.visibleTable = true;
          console.log("Error: ", err);
        });
    },
  },
};
</script>

<style>
.hospital-revenue-table .ant-card-head-title {
  color: #5b82be;
  font-weight: 600;
}
.hospital-revenue-table .ant-table-thead > tr > th {
  background: rgb(168, 228, 165, 0.5);
}
.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>
